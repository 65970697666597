/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import ArticleHeroHeadline from 'components/Article/ArticleHero/ArticleHeroHeadline';
import ArticleHeroFlag from 'components/Article/ArticleHero/ArticleHeroFlag';
import Sponsored from 'components/Sponsored';
import { Unibrow } from 'components/Unibrow';
import TodayLogoForRecipePrint from 'components/Recipe/TodayLogoForRecipePrint';
import Timestamp from 'components/Recipe/Timestamp';

import {
  getMedia,
  getMediaPresentationStyle,
  getDataAuthors,
  renderCaption,
  renderDek,
} from 'lib/articleUtils';
import { isShellArticle, isMSNBCDaily } from 'lib/article';
import { articleChildContextTypes } from 'lib/ContextTypes/article';
import { FeatureFlagContext } from 'lib/ContextTypes';
import {
  primaryMedia as primaryMediaPropType,
  socialMediaProfiles as socialPropType,
  heroLayoutType as heroLayoutTypePropType,
} from 'lib/CustomPropTypes';
import { sectionContainSlug } from 'lib/taxonomy';
import Breakpoints from 'lib/Breakpoints';
import { VIEW } from 'lib/view';
import './styles.themed.scss';

const block = 'article-hero';

const mapStateToProps = ({ shared, tve, video }) => ({
  socialMediaProfiles: shared.socialMediaProfiles,
  authenticated: tve.authenticated,
  hasTempPass: tve.hasTempPass,
  provider: tve.provider,
  videoError: video.error,
  view: shared.view,
});

// for SEO purposes, we only want the disclaimer rendered in the client and not SSR'ed
const DynamicDisclaimerDefault = dynamic(
  async () => {
    // need to access the named export DisclaimerDefault
    const { DisclaimerDefault } = await import('components/Disclaimer');
    return DisclaimerDefault;
  },
  { ssr: false },
);

class ArticleHero extends Component {
  static propTypes = {
    additionalClasses: PropTypes.shape({
      headline: PropTypes.shape({}),
    }),
    content: primaryMediaPropType,
    isChromeless: PropTypes.bool,
    isLiveBlog: PropTypes.bool,
    isRecipe: PropTypes.bool,
    isPresentationStyleWide: PropTypes.bool,
    path: PropTypes.string,
    primaryMediaBannerEnabled: PropTypes.bool,
    socialMediaProfiles: socialPropType,
    stylizedTag: PropTypes.element,
    vertical: PropTypes.string.isRequired,
    heroLayoutType: heroLayoutTypePropType,
    authenticated: PropTypes.bool,
    hasTempPass: PropTypes.bool,
    provider: PropTypes.bool,
    videoError: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    isShoppable: PropTypes.bool,
    view: PropTypes.string,
  };

  static contextType = FeatureFlagContext;

  static childContextTypes = articleChildContextTypes;

  static defaultProps = {
    additionalClasses: {
      headline: null,
    },
    content: null,
    isChromeless: false,
    isLiveBlog: false,
    isRecipe: false,
    isPresentationStyleWide: false,
    path: '',
    primaryMediaBannerEnabled: false,
    stylizedTag: null,
    heroLayoutType: {},
    authenticated: false,
    hasTempPass: false,
    provider: false,
    videoError: '',
    isShoppable: false,
    view: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasStickyVideo: false,
      isVideoPlaying: false,
    };
  }

  setStickyVideoFlag = (hasStickyVideo) => {
    this.setState({ hasStickyVideo });
  }

  setVideoPlayState = () => {
    this.setState({ isVideoPlaying: true });
  }

  getChildContext = () => {
    const {
      content: {
        taxonomy: {
          sections,
        },
      },
      isLiveBlog,
      isRecipe,
      isPresentationStyleWide,
      vertical,
      content,
      socialMediaProfiles,
      isChromeless,
      heroLayoutType,
      authenticated,
      hasTempPass,
      provider,
      videoError,
      primaryMediaBannerEnabled,
      isShoppable,
    } = this.props;

    const {
      hasStickyVideo,
      isVideoPlaying,
    } = this.state;
    const isMobile = Breakpoints.isSorM();

    const isShoppingSection = sectionContainSlug(sections, 'shopping');

    return {
      authenticated,
      block,
      content,
      hasStickyVideo,
      hasTempPass,
      isChromeless,
      isLiveBlog,
      isMobile,
      isRecipe,
      isShoppingSection,
      isVideoPlaying,
      isPresentationStyleWide,
      heroLayoutType,
      provider,
      socialMediaProfiles,
      vertical,
      videoError,
      primaryMediaBannerEnabled,
      setStickyVideoFlag: this.setStickyVideoFlag,
      setVideoPlayState: this.setVideoPlayState,
      pipAlignDesktop: isShoppable ? 'top' : 'bottom',
      pipAlignMobile: isShoppable ? 'top' : 'bottom',
    };
  };

  checkABTestingFeature = (feature) => {
    const { autoplayTestGroup } = this.state;
    return autoplayTestGroup === feature;
  }

  renderSponsor = () => {
    const { content: { nativeAd, source } } = this.props;
    if (!nativeAd || !source) {
      return null;
    }
    const { organization: { externalUrl, primaryImage } = {} } = source;
    return (
      <div className="article-hero__sponsor">
        <Sponsored
          externalUrl={externalUrl}
          primaryImage={primaryImage}
        />
      </div>
    );
  }

  renderHeroFlag = (isBreakingNews) => {
    const {
      heroLayoutType,
      isLiveBlog,
      vertical,
      content: {
        flag,
        taxonomy: {
          badge,
        },
      },
    } = this.props;

    const { 'article-badge': ARTICLE_BADGE } = this.context;

    const label = ARTICLE_BADGE ? badge?.name : flag;

    return isBreakingNews && !!label?.length ? (
      <ArticleHeroFlag
        isLiveBlog={isLiveBlog}
        vertical={vertical}
        label={label}
        heroLayoutType={heroLayoutType}
      />
    ) : null;
  }

  renderUnibrow = () => {
    const {
      content: {
        breakingNews,
        ecommerceEnabled,
        taxonomy,
        unibrow,
      },
      vertical,
      isRecipe,
      isPresentationStyleWide,
      isLiveBlog,
      heroLayoutType,
    } = this.props;

    const isBreakingNews = breakingNews && !isLiveBlog;
    return (
      <aside
        className={classNames(
          'article-hero__unibrow-grid layout-grid-item',
          {
            'grid-col-2-l': !heroLayoutType?.wide && !isRecipe,
            dn: isBreakingNews,
            'grid-col-11-xl grid-col-push-1-xl': heroLayoutType?.wide,
            'grid-col-11-m': isPresentationStyleWide,
            'grid-col-10-m grid-col-12-l grid-col-10-xl grid-col-push-1-m grid-col-push-0-l grid-col-push-1-xl': isRecipe,
          },
        )}
      >
        <Unibrow
          size="div"
          className={classNames(
            {
              'article-hero__tax-term': !heroLayoutType?.wide && !isRecipe,
              'article-hero__ecommerce-enabled': ecommerceEnabled,
              'article-hero__enhanced-opinion': isMSNBCDaily(vertical, taxonomy),
              'dn-print': isRecipe,
            },
          )}
          unibrow={unibrow}
          enableLogos
          hasDefaultTextStyle={heroLayoutType?.wide || isRecipe}
        />
      </aside>
    );
  }

  render() {
    const {
      additionalClasses,
      content: {
        breakingNews,
        headline,
      },
      content,
      isLiveBlog,
      stylizedTag,
      path,
      vertical,
      isRecipe,
      isPresentationStyleWide,
      heroLayoutType,
      view,
    } = this.props;

    if (isShellArticle(content)) {
      return null;
    }

    const ecommerceEnabled = content?.ecommerceEnabled;
    const disclaimer = content?.ecommerceMetadata?.disclaimer;
    const hasDisclaimerText = !!disclaimer?.body[0]?.html?.trim();

    const shouldShowDisclaimer = hasDisclaimerText && ecommerceEnabled;

    const mediaPresentationStyle = getMediaPresentationStyle(this.getChildContext());
    const { hasStickyVideo } = this.state;

    const media = getMedia(this.getChildContext());

    const isBreakingNews = breakingNews && !isLiveBlog;
    const isLiveBreakingNews = breakingNews || isLiveBlog;

    const containerClasses = classNames(
      'article-hero__container',
      {
        [mediaPresentationStyle]: !isPresentationStyleWide,
        'article-hero__container--breaking': isBreakingNews,
        'article-hero__live-blog': isLiveBlog,
        'article-hero__live-breaking': isLiveBreakingNews,
        'article-hero__sticky-video': hasStickyVideo,
        'article-hero__presentation-wide': isPresentationStyleWide,
        'bg-none-print': isRecipe,
      },
    );

    return (
      <section className={containerClasses} data-test={block} data-testid="article-hero">
        <div
          className={classNames(
            'article-hero__bg-container',
            {
              'article-hero__bg-container--recipe': isRecipe,
            },
          )}
          data-authors={getDataAuthors(this.getChildContext())}
        >
          {!isLiveBlog && !isLiveBreakingNews && <div className="article-hero__divider" />}
          {shouldShowDisclaimer && (
            <div className="disclaimer-container">
              <DynamicDisclaimerDefault
                type={disclaimer?.type}
                verbiage={disclaimer?.body[0]?.html}
              />
            </div>
          )}
          <header
            className={classNames(
              'article-hero__header',
              'mh0-print',
              'layout-grid-container',
              {
                'article-hero__is-tagged': !!stylizedTag || isLiveBreakingNews,
                'pb0-print pl0-print mb0-print article-hero__header--recipe': isRecipe,
                'article-hero__is-breaking--wide': isLiveBreakingNews && isPresentationStyleWide,
              },
            )}
            data-test="article-hero__header"
          >
            {stylizedTag}
            {this.renderHeroFlag(isBreakingNews)}
            {!isLiveBlog && view !== VIEW.START_TODAY_APP && this.renderUnibrow(this.getChildContext())}
            {isRecipe && <TodayLogoForRecipePrint />}
            <ArticleHeroHeadline
              additionalClasses={additionalClasses.headline}
              dek={renderDek(this.getChildContext(), isLiveBreakingNews)}
              isLiveBlog={isLiveBlog}
              isRecipe={isRecipe}
              isPresentationStyleWide={isPresentationStyleWide}
              heroLayoutType={heroLayoutType}
              path={path}
              timestamp={isRecipe ? <Timestamp content={content} /> : null} // for recipes only
              vertical={vertical}
            >
              {headline.primary}
            </ArticleHeroHeadline>
          </header>
        </div>
        <div className={classNames(
          'article-hero__media-holder',
          {
            'layout-grid-container': !isPresentationStyleWide,
            'dn-print': isRecipe,
            'article-hero__media-holder--compact': isLiveBreakingNews,
          },
        )}
        >
          {media && (
            <div
              className={classNames(
                'article-hero__media-container',
                {
                  'layout-grid-item': !isPresentationStyleWide,
                  'grid-col-10-xl grid-col-push-2-xl': !isLiveBlog && !heroLayoutType?.wide && !isRecipe && !isPresentationStyleWide,
                  'grid-col-7_5-l grid-col-6-xl grid-col-push-2-xl': isLiveBlog && !heroLayoutType?.wide && !isPresentationStyleWide,
                  'grid-col-11-m grid-col-10-xl grid-col-push-1-xl': heroLayoutType?.wide && !isPresentationStyleWide,
                  'grid-col-10-m grid-col-12-l grid-col-10-xl grid-col-push-1-m grid-col-push-0-l grid-col-push-1-xl': isRecipe && !isPresentationStyleWide,
                },
              )}
            >
              {media}
            </div>
          )}
          {renderCaption(this.getChildContext())}
          {this.renderSponsor()}
        </div>
      </section>
    );
  }
}

export default connect(mapStateToProps)(ArticleHero);
