import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { getSecondsElapsed } from 'lib/DateTime';
import getDayjsLocale from 'lib/Locale/getDayjsLocale';

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(localeData);

/**
 * Provides "Updated xxx ago" text
 * @param {String} datePublished
 * @param {String} dateCreated
 * @param {String} vertical
 * @returns {String}
 */
export const getTimestampText = (datePublished, dateCreated, vertical) => {
  if (datePublished === dateCreated) {
    return dayjs(datePublished).format('MMM. D, YYYY, h:mm A z');
  }

  const { t } = useTranslation();
  const timeElapsed = getSecondsElapsed(datePublished);
  if (timeElapsed < 60) {
    return t('Updated now');
  }

  const loc = getDayjsLocale(vertical);
  const fromNow = dayjs(datePublished).locale(loc).fromNow(true);

  return t('updated_time_ago', { time: fromNow });
};
