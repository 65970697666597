import React from 'react';
import Head from 'next/head';
import { string, arrayOf } from 'prop-types';
import { buildVideoObjectLDFromApiData } from 'lib/LDJson';
import { video as VideoPropType } from 'lib/CustomPropTypes';

export function VideoObjectLDScripts({ videos, vertical }) {
  return (
    <Head>
      {videos.map((video) => (
        <script
          key={`VideoObjectLD-${video.id}`}
          type="application/ld+json"
          dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
            __html: JSON.stringify(buildVideoObjectLDFromApiData({ video, vertical })),
          }}
        />
      ))}
    </Head>
  );
}

VideoObjectLDScripts.propTypes = {
  videos: arrayOf(VideoPropType),
  vertical: string,
};

VideoObjectLDScripts.defaultProps = {
  videos: [],
  vertical: '',
};
