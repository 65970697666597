const es = require('dayjs/locale/es');
const en = require('dayjs/locale/en');
const brandFeatures = require('../brandFeatures');

/**
 * Provides locale value to be used by formatDistance and related methods from date-fns
 * @requires dayjs
 * @param {String} vertical
 * @returns {Locale}
 */
const getDayjsLocale = (vertical) => {
  const { DEFAULT_LANGUAGE } = brandFeatures;
  const lang = DEFAULT_LANGUAGE[vertical];
  return lang === 'es' ? es : en;
};

module.exports = getDayjsLocale;
