import React from 'react';
import { useTracking } from 'lib/Hooks';

import { useMyNewsStore } from 'store';

import {
  AUTHENTICATED,
  UNAUTHENTICATED,
  ARTICLE,
  VIDEO,
  RECIPE,
} from 'lib/myNewsConstants';
import {
  video as videoPropType,
  recipe as recipePropType,
  article as articlePropType,
} from 'lib/CustomPropTypes';

export function useAccountWorkflows({ contentId, contentType }) {
  const authenticationState = useMyNewsStore((state) => state.authenticationState);
  const authenticate = useMyNewsStore((state) => state.authenticate);
  const createBookmark = useMyNewsStore((state) => state.createBookmark);

  const track = useTracking('mbt_mynews_save_click');

  function handleLogin() {
    authenticate();
    track({
      action: 'Login from URL param link',
      placement: 'URL param link',
      contentId,
    });
  }

  async function handleSave() {
    createBookmark({
      contentId,
      contentType,
    });
    track({
      action: 'Saved',
      placement: 'URL param link',
      contentId,
    });
  }

  React.useEffect(() => {
    if (authenticationState !== AUTHENTICATED && authenticationState !== UNAUTHENTICATED) return;

    // trigger to save the content if url param is present
    // this is fetched client side to avoid production server caching issues
    const searchParams = new URLSearchParams(window?.location?.search);
    const accountParam = searchParams.get('account');
    if (!accountParam || accountParam.toLowerCase() !== 'save') return;

    if (authenticationState === AUTHENTICATED) {
      handleSave();
    }

    if (authenticationState === UNAUTHENTICATED) {
      handleLogin();
    }
  }, [authenticationState]);
}

export const withAccountWorkflows = (Component) => {
  const AccountWorkflows = (props) => {
    const {
      article, recipe, video,
    } = props;

    let contentId;
    let contentType;
    if (article) {
      contentId = article.id;
      contentType = ARTICLE;
    }
    if (recipe) {
      contentId = recipe.id;
      contentType = RECIPE;
    }
    if (video) {
      contentId = video.id;
      contentType = VIDEO;
    }

    useAccountWorkflows({ contentId, contentType });
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} />;
  };

  if (Component.getInitialProps) {
    AccountWorkflows.getInitialProps = Component.getInitialProps;
  }

  AccountWorkflows.propTypes = {
    article: articlePropType,
    recipe: recipePropType,
    video: videoPropType,
  };

  AccountWorkflows.defaultProps = {
    article: undefined,
    recipe: undefined,
    video: undefined,
  };

  return AccountWorkflows;
};
