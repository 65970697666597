import React from 'react';
import TodayLogo from 'components/TodayLogo';

const TodayLogoForRecipePrint = () => (
  <div className="dn db-print layout-grid-item">
    <TodayLogo textColor="black" sunriseColor="#FF503C" width="60" height="12" />
  </div>
);

export default TodayLogoForRecipePrint;
