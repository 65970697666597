import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getTimestampText } from 'lib/getTimestampText';

import { ArticleContext } from 'lib/ContextTypes';
import LiveFlag from 'components/LiveFlag';

import { heroLayoutType as heroLayoutTypePropType } from 'lib/CustomPropTypes';

const ArticleHeroFlag = ({
  heroLayoutType,
  isLiveBlog,
  label,
  vertical,
}) => {
  const {
    breakingNews, dateCreated, datePublished,
  } = useContext(ArticleContext);

  const isBreakingNews = breakingNews && !isLiveBlog;

  if (!isBreakingNews || !label?.length) {
    return null;
  }

  const { t } = useTranslation();

  return (
    <LiveFlag
      isBreaking
      label={t(label)}
      heroLayoutType={heroLayoutType}
      timestampWithUpdatedText={getTimestampText(
        datePublished,
        dateCreated,
        vertical,
      )}
    />
  );
};

ArticleHeroFlag.defaultProps = {
  heroLayoutType: {},
};

ArticleHeroFlag.propTypes = {
  heroLayoutType: heroLayoutTypePropType,
  isLiveBlog: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  vertical: PropTypes.string.isRequired,
};

export default ArticleHeroFlag;
