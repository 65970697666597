/**
 * Retrieves all videos from article payload.
 * @param {object} article The article.
 * @returns {array} The videos in primary media and body of the payload.
 */
export const getVideosFromArticlePayload = (article) => {
  const videos = [];

  if (!article) {
    return videos;
  }

  if (article.primaryMedia?.video) {
    videos.push(article.primaryMedia.video);
  }

  if (Array.isArray(article.body)) {
    article.body.forEach((item) => {
      if (item.type === 'embeddedVideo' && item.video) {
        videos.push(item.video);
      }
    });
  }
  return videos;
};

/**
 * Retrieves all videos from recipe payload.
 * @param {object} data The recipe.
 * @returns {array} The videos in primary media and body of the payload.
 */
export const getVideosFromRecipePayload = (recipe) => {
  const videos = [];

  if (!recipe) {
    return videos;
  }

  if (recipe.primaryMedia?.video) {
    videos.push(recipe.primaryMedia.video);
  }

  if (recipe.associatedVideo) {
    videos.push(recipe.associatedVideo);
  }

  return videos;
};

/**
 * Retrieves all videos in from live blog payload.
 * @param {object} data - Object containing Live Blog article payload and live blog cards
 * @param {object} data.article - The article payload associated with the live blog
 * @param {Array} data.liveBlogCards - The active Live Blog cards.
 * @returns {array} The videos in primary media, body of the payload, and live blog cards.
 */
export const getVideosFromLiveBlog = ({ article, liveBlogCards } = {}) => {
  const videos = getVideosFromArticlePayload(article);

  if (Array.isArray(liveBlogCards)) {
    liveBlogCards.flatMap((card) => card.content?.markupAndEmbeds || [])
      .filter((item) => item.type === 'embeddedVideo' && item.video)
      .forEach((item) => videos.push(item.video));
  }

  return videos;
};
