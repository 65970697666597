import React from 'react';
import classNames from 'classnames';
import ContentTimestamp from 'components/ContentTimestamp';
import { video as videoPropType } from 'lib/CustomPropTypes';

import './styles.themed.scss';

const Timestamp = ({ content }) => {
  const { dateCreated, datePublished, dateModified } = content;

  return (
    <div className={classNames('recipe__time-stamp dn-print')}>
      <ContentTimestamp
        dateCreated={dateCreated}
        datePublished={dateModified || datePublished}
        delimiter=", "
        meta
        showCreatedDate
        timeZone="EST"
      />
    </div>
  );
};

Timestamp.propTypes = {
  content: videoPropType.isRequired,
};

export default Timestamp;
