import React from 'react';
import PropTypes from 'prop-types';

const TodayLogo = ({
  sunriseColor, textColor, width, height,
}) => (
  /* eslint-disable max-len */
  <svg width={width} height={height} viewBox="0 0 137 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M40.6504 1.73926V6.09822H45.899V20.6057H50.8771V6.09822H56.0944V1.73926H40.6504Z" fill={textColor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M72.0826 12.8532C71.8553 13.4733 71.5132 14.0451 71.0742 14.5386C70.6343 15.023 70.0973 15.4092 69.4982 15.6721C68.8837 15.9457 68.1896 16.0849 67.4391 16.0849C66.6683 16.0849 65.9647 15.9457 65.3503 15.6721C64.7516 15.41 64.2155 15.0236 63.7774 14.5386C63.3358 14.0465 62.9925 13.4744 62.7659 12.8532C62.5267 12.209 62.4078 11.5008 62.4078 10.7441C62.4078 9.99203 62.5267 9.28378 62.7659 8.63806C62.9921 8.01623 63.3355 7.44355 63.7774 6.95108C64.2168 6.46834 64.7527 6.08324 65.3503 5.82069C65.9647 5.54551 66.6667 5.40324 67.4391 5.40324C68.1911 5.40324 68.8837 5.54551 69.4982 5.82069C70.0961 6.08439 70.6327 6.4693 71.0742 6.95108C71.5026 7.42794 71.8434 7.99548 72.0826 8.63806C72.3271 9.31304 72.4494 10.0262 72.4438 10.7441C72.4438 11.5008 72.3218 12.2075 72.0826 12.8532V12.8532ZM74.6326 3.71625C73.743 2.86259 72.6658 2.18717 71.4369 1.71031C70.208 1.22876 68.8634 0.98877 67.4391 0.98877C65.996 0.98877 64.6452 1.22798 63.4257 1.70953C62.2077 2.18639 61.132 2.86025 60.2315 3.71547C59.3186 4.58688 58.5957 5.63764 58.1083 6.80176C57.6002 7.99313 57.3438 9.31974 57.3438 10.7441C57.3438 12.17 57.6002 13.4989 58.1083 14.6903C58.5954 15.8537 59.3183 16.9035 60.2315 17.7734C61.1305 18.6271 62.2062 19.3041 63.4257 19.7825C64.6452 20.2594 65.9944 20.5017 67.4391 20.5017C68.8634 20.5017 70.2096 20.2594 71.4369 19.7825C72.6674 19.3041 73.743 18.6271 74.6326 17.7734C75.5254 16.9182 76.2336 15.8816 76.7402 14.6903C77.2468 13.5005 77.5047 12.17 77.5047 10.7441C77.5047 9.31974 77.2468 7.99391 76.7402 6.80255C76.255 5.6411 75.5378 4.59091 74.6326 3.71625" fill={textColor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M93.2218 13.1088C93.0021 13.7089 92.6639 14.2587 92.2274 14.7255C91.7747 15.1996 91.2292 15.5753 90.6248 15.8293C89.9932 16.106 89.2615 16.2467 88.4516 16.2467H85.4967V6.09979H88.4798C89.3272 6.09979 90.0776 6.23112 90.7108 6.48753C91.3425 6.74237 91.8756 7.10197 92.2962 7.56007C92.7183 8.0166 93.0389 8.55757 93.2499 9.16263C93.4626 9.7677 93.5673 10.44 93.5673 11.1576C93.5673 11.8393 93.4516 12.4928 93.2218 13.1088V13.1088ZM95.7468 4.25332C94.8478 3.44657 93.7643 2.81805 92.526 2.3881C91.2909 1.95502 89.915 1.73926 88.4407 1.73926H80.5186V20.6057H88.4673C89.9275 20.6057 91.2925 20.3868 92.526 19.9569C93.7627 19.5253 94.8478 18.8921 95.7468 18.076C96.6564 17.2467 97.3787 16.2329 97.8653 15.1023C98.3734 13.9469 98.6298 12.621 98.6298 11.1576C98.6298 9.69421 98.3734 8.36995 97.8653 7.21298C97.3809 6.08518 96.6582 5.07553 95.7468 4.25332V4.25332ZM106.403 13.556L108.683 6.82055L110.963 13.556H106.403ZM111.766 1.73926H105.601L98.5376 20.5056L98.4985 20.6088H104.135L105.06 17.915H112.334L113.261 20.6088H118.896L111.785 1.78929L111.766 1.73926Z" fill={textColor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M130.904 1.29614L126.933 8.1254L122.929 1.3321L122.907 1.29614H117.284L124.445 12.5641V20.1641H129.423V12.5641L136.557 1.29614H130.904Z" fill={textColor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M36.7698 19.8269C36.3758 10.0176 28.2997 2.18774 18.3943 2.18774C8.55612 2.18774 0.520644 9.91287 0.0250223 19.6267C0.00806966 19.9444 -0.00027193 20.2624 6.75736e-06 20.5805H4.7686C4.7686 20.2724 4.77955 19.9629 4.80065 19.658C5.27751 12.5739 11.1906 6.95712 18.3935 6.95712C25.623 6.95712 31.5556 12.6169 31.9918 19.7393C32.009 20.0184 32.0176 20.2975 32.0176 20.5797H36.7862C36.7862 20.3295 36.7815 20.0754 36.7706 19.8269" fill={sunriseColor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.3946 8.31824C11.8929 8.31824 6.57479 13.3776 6.1589 19.7722C6.13936 20.038 6.13232 20.3077 6.13232 20.5798H10.9009C10.9009 20.2882 10.9173 19.9974 10.951 19.7128C11.3817 15.9902 14.5571 13.0868 18.3939 13.0868C22.2525 13.0868 25.4397 16.0183 25.8438 19.7699C25.8735 20.0365 25.8876 20.3054 25.8876 20.5798H30.6562C30.6562 20.3171 30.6484 20.0607 30.632 19.8012C30.2301 13.3917 24.9042 8.31824 18.3939 8.31824" fill={sunriseColor} />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.3943 14.4502C17.5891 14.4501 16.7917 14.6086 16.0477 14.9168C15.3038 15.2249 14.6278 15.6766 14.0585 16.246C13.4891 16.8155 13.0375 17.4915 12.7295 18.2355C12.4214 18.9795 12.263 19.7769 12.2632 20.5821H24.5247C24.5249 19.777 24.3665 18.9796 24.0585 18.2357C23.7505 17.4917 23.299 16.8157 22.7297 16.2463C22.1605 15.6769 21.4846 15.2252 20.7407 14.917C19.9968 14.6088 19.1995 14.4502 18.3943 14.4502" fill={sunriseColor} />
  </svg>
  /* eslint-enable max-len */
);

TodayLogo.propTypes = {
  sunriseColor: PropTypes.string,
  textColor: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

TodayLogo.defaultProps = {
  sunriseColor: 'white',
  textColor: 'white',
  width: '137',
  height: '21',
};

export default TodayLogo;
