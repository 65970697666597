const article = (id) => {
  const isNumericalId = /^\d+$/.test(id);

  if (isNumericalId) {
    if (id.length >= 7) {
      return `wbna${id}`;
    }

    return `flna${id}`;
  }

  return id.replace(/n(?:cna)?(\d+)/, 'ncna$1')
    .replace(/t(?:dna)?(\d+)/, 'tdna$1')
    .replace(/(\d{1}[A-Fa-f]{1}\d+)$/, (match) => `flna${match.toUpperCase()}`)
    .replace(/[iI](\d+)/, 'flnaI$1')
    .replace(/[fF](\d+)/, 'flna$1');
};

const slideshow = (id) => id.replace(/n(?:csl)?(\d+)/, 'ncsl$1')
  .replace(/t(?:dsl)?(\d+)/, 'tdsl$1')
  .replace(/^(\d+)$/, 'wbsl$1');

const recipe = (id) => id.replace(/t(?:drp)?(\d+)/, 'tdrp$1');

export default {
  article,
  recipe,
  slideshow,
};
